import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menu | 🔥 The Mige Myi Sports Pub 🍺
			</title>
			<meta name={"description"} content={"Dive into this quick glance of what awaits you, and let your senses be transported to a realm where history meets culinary excellence."} />
			<meta property={"og:title"} content={"Menu | 🔥 The Mige Myi Sports Pub 🍺"} />
			<meta property={"og:description"} content={"Dive into this quick glance of what awaits you, and let your senses be transported to a realm where history meets culinary excellence."} />
			<link rel={"shortcut icon"} href={"https://migemyi.com/img/1748081.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://migemyi.com/img/1748081.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://migemyi.com/img/1748081.png"} />
			<meta name={"msapplication-TileImage"} content={"https://migemyi.com/img/1748081.png"} />
			<meta name={"msapplication-TileColor"} content={"https://migemyi.com/img/1748081.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-5" background="--color-darkL2">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--light"
			>
				Menu
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--light"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				🍽️ Mige Myi's Culinary Chronicles 🍺{"\n\n"}
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--light"
				padding="0px 20px 0px 20px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Embark on a gastronomic journey through the ages at The Mige Myi Sports Pub. Our menu, inspired by the robust flavours of medieval times, is crafted to satiate the palates of modern-day champions. Here, tradition meets taste, as every dish and drink tells a story, woven with love and a hint of nostalgia.{"\n"}
				<br />
				<br />
				{"\n"}From the robust brews that honour ancient recipes to the contemporary twists on classic delicacies, our offerings are a testament to the union of old-world charm and current culinary innovation. Whether you’re toasting to victory or feasting in good company, our menu promises an experience that's both timeless and memorable.{"\n\n"}
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-light"
				width="40px"
				border-width="2px 0 0 0"
				color="--light"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://migemyi.com/img/menu_12.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						🍻 Brews of Honour{"\n\n"}
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-light"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{"\n"}Knight's Goblet:
						</Strong>
						{" "}A hearty dark ale, with hints of chocolate and coffee.{"\n"}
						<br />
						<Strong>
							Maiden's Whisper:
						</Strong>
						{" "}A refreshing white beer, infused with citrus undertones.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{"\n"}Dragon's Breath:
						</Strong>
						{" "}A spicy stout, for those who dare to take on the heat.{"\n\n"}
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Image
							src="https://migemyi.com/img/menu_1.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
							🍔 Main Course{"\n\n"}
						</Text>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-light"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							<Strong>
								{"\n"}King's Burger:
							</Strong>
							{" "}Juicy beef patty topped with blue cheese, arugula, and a special house sauce. Served with a side of thick-cut fries.{"\n"}
							<br />
							<Strong>
								Scepter's Veg Pie:{" "}
							</Strong>
							A savoury pie filled with roasted vegetables and seasoned with an array of herbs.{"\n\n"}
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Image
							src="https://migemyi.com/img/menu_3.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
							🍨 Desserts{"\n\n"}
						</Text>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-light"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							<Strong>
								{"\n"}Minstrel's Melody:
							</Strong>
							{" "}Creamy cheesecake with a berry compote.{"\n"}
							<br />
							<Strong>
								Torchlit Tiramisu:
							</Strong>
							{" "}A classic dessert with a touch of coffee and cocoa, honouring timeless traditions.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://migemyi.com/img/menu_11.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						🍸 Speciality Cocktails{"\n\n"}
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-light"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							{"\n"}Chalice Charm:{" "}
						</Strong>
						A mix of gin, elderflower liqueur, and fresh lime juice.{"\n"}
						<br />
						<Strong>
							Moat's Mystery:{" "}
						</Strong>
						A blend of dark rum, ginger beer, and a hint of lime.{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Image19 />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652e9dee47aa25001fb0714b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});